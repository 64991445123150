import dev from './dev/config';
import prod from './prod/config';
import local from './local/config';

// @ts-ignore This isn't normall here but we need it for invoke-entities
window.process = window.process ?? { env: {} };
const pointToProd = import.meta.env.VITE_CONFIG === 'prod';

let config;
switch (window.location.host) {
  case 'app.equips.com':
    window.process.env.frontendDomain = prod.host.url;
    window.process.env.stage = 'prod';
    config = prod;
    break;
  case 'dev.app.equips.com':
    window.process.env.frontendDomain = dev.host.url;
    window.process.env.stage = 'dev';
    config = dev;
    break;
  default:
    window.process.env.frontendDomain = local.host.url;
    window.process.env.stage = pointToProd ? 'prod' : 'dev';
    config = pointToProd ? prod : import.meta.env.VITE_LOCAL ? local : dev;
    break;
}

if (!config) {
  throw new Error(`There is no config environment defined`);
}

const commonConfigValues = {
  builtAtTimestamp: Number(__buildDate__),
  tmsBaseUrl: 'http://18.215.77.181:8090/',
};

export default {
  ...commonConfigValues,
  ...config,
};
