export default {
  stage: 'prod',
  isProd: true,
  apiGateway: {
    name: `equips-app`,
    region: 'us-east-1',
    endpoint: `https://jtx1bd1ly6.execute-api.us-east-1.amazonaws.com/prod`,
  },
  cognito: {
    mandatorySignIn: true,
    region: 'us-east-1',
    userPoolId: 'us-east-1_n1yUytxA6',
    identityPoolId: 'us-east-1:72c8911e-5ff7-446b-a56b-d9cc0d05cdca',
    userPoolWebClientId: 'bf7i04oq3rej58480401tfpau', // App client ID
  },
  heap: {
    apiKey: '4031887271',
  },
  users: {
    systemImportUserGuid: '9020947e-66fe-4702-81af-9624b8aca1d1',
  },
  host: {
    url: 'https://app.equips.com',
  },
  googleAnalytics: {
    trackingID: 'G-Q92BGY1WH8',
  },
  defaultSRDetailsForSoftwareIssues: {
    organizationId: 'e0635373972843d1899de6a14fb5c421',
    locationId: 'fbd9a882f90f4fe5a2d6b30799361bda',
    equipmentId: '9c342b0cbf6849afb04dfb3c26db03e5',
  },
};
